import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "list-map" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "list-map-item-avatar" }
const _hoisted_4 = { class: "maps-list-item-img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: _imports_0
}
const _hoisted_7 = { class: "list-map-item-content" }
const _hoisted_8 = { class: "text-body-2" }
const _hoisted_9 = { style: {"position":"relative"} }
const _hoisted_10 = {
  key: 0,
  class: "maps-list-item-status text bg-green"
}
const _hoisted_11 = {
  key: 0,
  class: "maps-list-item-res mt-1 mx-1"
}
const _hoisted_12 = { class: "row mx-n1" }
const _hoisted_13 = { class: "maps-list-item-res-item" }
const _hoisted_14 = { class: "maps-list-item-img small" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  src: _imports_0
}
const _hoisted_17 = { class: "maps-list-item-link mb-0 ml-1 text-left" }
const _hoisted_18 = { class: "list-map-item-action ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_tooltip = _resolveComponent("app-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "list-map-item px-3",
        key: i,
        onClick: ($event: any) => (_ctx.$emit('next-page', item.id_res))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            ((item.avatar ? item.avatar : "").indexOf("base64") > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: item.avatar
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", _hoisted_6))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createTextVNode(_toDisplayString(item.name) + " ", 1),
              (item.res.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10))
                : _createCommentVNode("", true)
            ])
          ]),
          (item.res.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.res, (res, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-auto pa-1",
                      key: i
                    }, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, [
                          ((res.avatar ? res.avatar : "").indexOf("base64") > 0)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: res.avatar
                              }, null, 8, _hoisted_15))
                            : (_openBlock(), _createElementBlock("img", _hoisted_16))
                        ]),
                        _createElementVNode("p", _hoisted_17, _toDisplayString(res.name), 1)
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
            content: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("button.details")) + " " + _toDisplayString(_ctx.t("dictionary.typeResource")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                icon: "",
                onClick: _withModifiers(($event: any) => (_ctx.$emit("open-details", item)), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    icon: "info",
                    size: 16
                  })
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}