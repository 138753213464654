
import {defineComponent, computed, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from "vue-router";
import {Maps} from "@/models";

export default defineComponent({
	emits: ['next-page', 'open-details'],
	props: {
		item: {
			type: Object as PropType<Maps.LinkedResource>,
			required: true
		}
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();

		const routeQueryIds = computed(() => {
			const ids = route.query.id
			return Object.assign([], ids ? typeof ids === 'object' ? ids : [ids] : []).map(id => Number(id))
		})

		const resources = (res: any) => res
			.filter(el => !routeQueryIds.value.includes(el.id_res))
			.filter(el => el.id_res != route.params.idRes);

		return { t, resources }
	}
})
