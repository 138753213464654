import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-label mt-4"
}
const _hoisted_2 = { class: "row ma-n2" }
const _hoisted_3 = {
  key: 0,
  class: "col-12 pa-2"
}
const _hoisted_4 = {
  key: 1,
  class: "text-body-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_ListMap = _resolveComponent("ListMap")!
  const _component_GridMap = _resolveComponent("GridMap")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_spinner, {
      loading: _ctx.loading,
      wide: ""
    }, null, 8, ["loading"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.linkedResources.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupBy(_ctx.linkedResources, "id_type_res"), (group, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  (_ctx.typeResources.find(typeResource => typeResource.id == i))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.typeResources.find(typeResource => typeResource.id == i).name), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_2, [
                    (_ctx.mapView === "list")
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_ListMap, {
                            group: group,
                            onNextPage: _ctx.nextTree,
                            onOpenDetails: _ctx.openDetails
                          }, null, 8, ["group", "onNextPage", "onOpenDetails"])
                        ]))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(group, (item, i) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(["pa-2", _ctx.isTablet ? (!(_ctx.store.state.globals.detailsTypeResource || _ctx.store.state.globals.detailsResource) && _ctx.isLaptop ? "col-2" : "col-3") : "col-6"]),
                            key: i
                          }, [
                            _createVNode(_component_GridMap, {
                              item: item,
                              onNextPage: _ctx.nextTree,
                              onOpenDetails: _ctx.openDetails
                            }, null, 8, ["item", "onNextPage", "onOpenDetails"])
                          ], 2))
                        }), 128))
                  ])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.t("form.notify.noResultsFound")), 1))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}