
import {defineComponent, ref, onMounted, computed, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRouter, useRoute} from "vue-router";
import ListMap from "@/views/maps/components/linkedResources/components/ListMap.vue";
import GridMap from "@/views/maps/components/linkedResources/components/GridMap.vue";
import {TypeResources, TypeLinks, Maps} from "@/models";
import groupBy from 'lodash/groupBy'

export default defineComponent({
	emits: ["item-refresh-request", "open-details"],
	props: {
		detailsId: Number,
		isRefreshRequest: {
			type: Boolean,
			default: false
		},
		selectedTypeLink: {
			type: Array as PropType<TypeLinks.TypeLink[]>,
			default: () => []
		}
	},
	components: {
		ListMap,
		GridMap
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();

		const mapView = computed(() => store.state.globals.mapView);

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);

		const routeQueryIds = computed(() => {
			const ids = route.query.id
			return Object.assign([], ids ? typeof ids === 'object' ? ids : [ids] : []).map(id => Number(id))
		})

		const linkedResources = computed(() => {
			if(routeQueryIds.value.length) {
				return store.state.dictionaries.linkedResources
					.filter((el: any) => !routeQueryIds.value.includes(el.id_res))
					.filter((el: any) => el.id_res != route.params.idRes);
			} else {
				return store.state.dictionaries.linkedResources
			}
		});

		const loading = ref(true);
		const load = async (id: number) => {
			loading.value = true;
			await store.dispatch('dictionaries/getLinkedResources', {
				id_res: id ?? route.params.idRes,
				lst_of_links: props.selectedTypeLink
			});
			loading.value = false;
			emit('item-refresh-request');

			if(store.state.globals.detailsResource) {
				await store.dispatch('dictionaries/getResources')
				props.detailsId === linkedResource.value?.id_res
					? openDetails(linkedResource.value)
					: emit("open-details", props.detailsId, "resource")
			}
		}

		const refreshLoad = async () => {
			await load(routeQueryIds.value.length ? routeQueryIds.value.slice(-1)[0] : Number(route.params.idRes));
		}

		onMounted(async () => {
			await store.dispatch('dictionaries/getTypeResources');
			await store.dispatch('dictionaries/getResources');
			await store.dispatch('dictionaries/getMaps', route.params.idTypeRes);
			await store.dispatch('dictionaries/getTypeLinks');
			refreshLoad();
		});

		const router = useRouter();

		const nextTree = (id: number) => {
			const ids = routeQueryIds.value;
			ids.push(id);

			return router.replace({ query: {id: ids} });
		}

		watch(() => route.params.idRes, async () => {
			if(route.params.idRes) {
				await store.dispatch('dictionaries/getMaps', route.params.idTypeRes);
				refreshLoad();
			}
		})

		watch(() => route.query.id, async () => {
			if(routeQueryIds.value.length ? routeQueryIds.value.slice(-1)[0] : Number(route.params.idRes))
				refreshLoad();
		})

		watch(() => props.isRefreshRequest, async () => {
			if(props.isRefreshRequest) {
				refreshLoad();
			}
		});

		watch(() => props.selectedTypeLink, async () => {
			refreshLoad();
		});

		const isTablet = ref(true);
		isTablet.value = window.innerWidth >= 600;
		window.addEventListener('resize', () => isTablet.value = window.innerWidth >= 600);

		const isLaptop = ref(true);
		isLaptop.value = window.innerWidth >= 1264;
		window.addEventListener('resize', () => isLaptop.value = window.innerWidth >= 1264);

		const linkedResource = ref()
		const openDetails = (value: Maps.LinkedResource) => {
			linkedResource.value = value
			emit("open-details", linkedResource.value.id_res, "resource", {id_pair: linkedResource.value.id_pair, fixed: linkedResource.value.fixed})
		}

		return {
			t, store,
			mapView, nextTree,
			loading, load, refreshLoad,
			linkedResources,
			isTablet, isLaptop,
			groupBy, typeResources,
			openDetails
		}
	}
})
